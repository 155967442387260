<template>
	<div>
		<div
			v-if="!videoUrl && imageSrc"
			class="hidden-sm"
		>
			<LazyBackgroundImage
				:alt="image.alt"
				:src="imageSrc"
				class="image"
			></LazyBackgroundImage>
		</div>

		<figure
			v-if="image && !videoUrl"
			class="visible-sm"
		>
			<LazyImageResponsive
				:aspect-ratio="aspectRatio"
				:aspect-ratio-mobile="aspectRatioMobile"
				:auto-aspect="autoAspect"
				class="responsive-image"
				v-bind="image"
			/>
		</figure>

		<div
			class="hidden-sm video-container"
			v-if="videoUrl"
		>
			<div class="video-wrapper">
				<LazyTnVideo
					v-if="videoUrl"
					ref="videoPlayer"
					:autoplay="video.autoplay"
					:controls="video.disableControls"
					:loop="video.loop"
					:muted="video.muted"
					:src-desktop="video.landscapeVideoLink"
					:src-mobile="video.portraitVideoLink"
					:src-youtube="video.url"
				/>
			</div>
			<div class="video-click-blocker"></div>
		</div>
	</div>
</template>

<script>
const formatSource = (src) => {
	if (src) {
		const parts = src.split("/");
		parts[parts.length - 1] = parts[parts.length - 1].split("?")[0];
		parts[parts.length - 1] = encodeURIComponent(parts[parts.length - 1]);
		return parts.join("/");
	}
};

export default defineNuxtComponent({
	name: "Banner5050Media",
	props: {
		image: {
			type: Object,
		},
		video: {
			type: Object,
		},
		aspectRatio: {},
		aspectRatioMobile: {},
		autoAspect: {
			type: Boolean,
		},
	},

	computed: {
		imageSrc() {
			return formatSource(this.image?.md);
		},
		videoUrl() {
			return this.video?.landscapeVideoLink || this.video?.portraitVideoLink || this.video?.url;
		},
	},
});
</script>

<style lang="scss" scoped>
.responsive-image {
	min-width: 100%;

	:deep(.main.ratio) {
		width: 100%;
	}

	:deep(img) {
		width: 100%;

		@include breakpoint(mobile) {
			min-height: 350px;
			object-fit: cover;
			object-position: center;
		}

		@include breakpoint(tablet) {
			min-height: 450px;
			object-fit: cover;
			object-position: center;
		}
	}

	@media screen and (max-width: $size-screen-s) {
		width: 100%;
		min-width: auto;
	}
}

.image {
	position: absolute;
	top: 0;
	left: 0;
	min-height: 360px;
	width: 100%;

	&.main {
		width: 100%;
		min-height: 360px;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
	}
}

.bigger .image {
	min-height: 475px;

	&.main {
		width: 100%;
		min-height: 475px;
	}

	@include breakpoint(mobile) {
		min-height: 0;

		&.main {
			width: 100%;
			min-height: 0;
		}
	}
}

.video-container {
	min-height: 360px;
	max-width: 100%;
	position: relative;
	overflow: hidden;

	.video-wrapper {
		min-height: inherit;
		position: absolute;
		min-width: 100%;
		width: 1422.22222px;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		margin: auto;

		:deep(iframe) {
			min-height: 360px;
		}
	}
}

.bigger .media .video-container {
	min-height: 475px;

	.video-wrapper :deep(iframe) {
		min-height: 475px;
	}
}

.video-click-blocker {
	position: absolute;
	inset: 0;
	z-index: 1;
}

.visible-sm {
	display: none;
}

.hidden-sm {
	display: block;
}
</style>
