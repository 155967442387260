<template>
	<section>
		<div :class="['banner-5050', classes, backgroundColor, { customBg: customBg.length > 0 }]">
			<a
				v-if="linkImage"
				:href="linkImage.href"
				:title="linkImage.title"
				class="media"
			>
				<Banner5050Media
					:image="image"
					:video="content.bannerVideo"
					aspect-ratio="595:360"
					aspect-ratio-mobile="595:360"
					class="media"
				/>
			</a>
			<Banner5050Media
				v-else
				:image="image"
				:video="content.bannerVideo"
				aspect-ratio="595:360"
				aspect-ratio-mobile="595:360"
				class="media"
			/>
			<Banner5050Content
				:address-search="content.addressSearch"
				:alignedLeft="content.alignedLeft"
				:bedriftCategory="metadata.bedriftCategory"
				:bedriftLead="metadata.bedriftLead"
				:class="{ 'padding-s': content.useAddressSearch }"
				:dark="darkCta"
				:heading="content.heading"
				:link="link"
				:showAddressSearch="content.useAddressSearch"
				:smallText="content.smallText"
				:text="content.text"
				class="content-container"
			/>
		</div>
	</section>
</template>

<script>
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";
import ResponsiveImageViewModel from "~/helpers/ViewModels/ResponsiveImageViewModel.js";

import backgroundColor from "../../../../mixins/BackgroundColor.js";
import SupportsDarkMode from "../../../../mixins/SupportsDarkMode";
import GizmoComponentMixin from "../../../../mixins/GizmoComponentMixin.js";

export default defineNuxtComponent({
	name: "Banner5050",

	mixins: [backgroundColor, SupportsDarkMode, GizmoComponentMixin],

	computed: {
		link() {
			if (this.content.link) {
				let link = LinkViewModel(this.content.link);
				if (link === null) link = {};
				else {
					link.text = this.content.linkText;
					link.displayAsButton = !!this.content.displayLinkAsButton;
					if (link.displayAsButton && this.content.link.externalUrl) {
						link.href = this.content.link.externalUrl;
						link.to = null;
					}
				}
				return link;
			} else {
				return null;
			}
		},
		color() {
			return this.content.backgroundColor || "blue";
		},
		image() {
			return this.content.image && ResponsiveImageViewModel(this.content.image);
		},
		linkImage() {
			return this.content.linkImage && LinkViewModel(this.content.linkImage);
		},
		classes() {
			let classes = "";
			if (this.content.bannerHeight === "large (475px)") classes += " bigger";
			if (this.content.reverse) classes += " flip";
			if (this.dark || this.content.backgroundColor === "dark") classes += " dark";
			return classes;
		},
		customBg() {
			if (this.content.customBackgroundColor) {
				return this.content.customBackgroundColor;
			} else {
				return "";
			}
		},
		darkCta() {
			return this.color === "dark" || this.color === "mid-blue";
		},
	},

	provide() {
		return {
			includesAddressSearch: true,
		};
	},
});
</script>

<style lang="scss" scoped>
.banner-5050 {
	display: flex;
	flex-flow: row wrap;
	width: 100%;
	font-family: $font-family-telenor-ui;
	max-height: none;
	position: relative;

	.customBg {
		background: v-bind("customBg") !important;
	}

	.media {
		min-height: 200px;
	}

	&,
	& .content-container,
	& .media {
		min-height: 360px;

		:deep(iframe) {
			min-height: 360px;
		}

		@include breakpoint(tablet) {
			height: auto;
		}
	}

	&.bigger,
	&.bigger .content-container,
	&.bigger .media {
		min-height: 475px;

		:deep(iframe) {
			min-height: 475px;
		}

		@include breakpoint(tablet) {
			height: auto;
		}

		@include breakpoint(mobile) {
			min-height: 0;

			:deep(.media) {
				height: 245px;
			}

			:deep(iframe) {
				height: 245px;
				min-height: 0;
			}
		}
	}

	> .media {
		display: block;
		flex-basis: 50%;
		max-width: 50%;
		position: relative;
		order: 1;

		@media screen and (max-width: $size-screen-s) {
			flex-basis: 100%;
			max-width: 100%;
		}
	}

	.content-container {
		display: flex;
		flex-basis: 50%;
		max-width: 50%;
		height: inherit;
		order: 2;
		padding: $spacing-3xl;
		background: transparent;
		justify-content: center;

		@media screen and (max-width: $size-screen-xs) {
			min-height: 0;
			height: auto;
		}

		@media screen and (max-width: $size-screen-s) {
			flex-basis: 100%;
			max-width: 100%;
			padding: $spacing-l;
		}
	}

	&.color-theme--mid-blue,
	&.color-theme--dark {
		:deep() {
			.content--text__small {
				color: $color-neutrals-50-tint;
			}
		}
	}

	&.flip {
		.content-container {
			order: 1;

			@include breakpoint(mobile) {
				order: 2;
			}

			@include breakpoint(tablet) {
				order: 2;
			}
		}

		.media {
			order: 2;

			:deep(img) {
				vertical-align: bottom;
			}

			@include breakpoint(mobile) {
				order: 1;
			}

			@include breakpoint(tablet) {
				order: 1;
			}
		}
	}

	&.dark {
		.content-heading {
			color: $color-neutrals-white;
		}

		.richtext,
		.medium,
		.small {
			color: $color-twe-text;
		}

		.link {
			color: $color-twe-cta-default;
		}

		.link.arrow-animated::after {
			border-color: $color-twe-cta-default;
		}

		.link:hover {
			color: $color-twe-cta-default;
		}

		.link:hover.arrow-animated::after {
			border-color: $color-twe-cta-default;
		}
	}

	@include breakpoint(mobile) {
		min-height: 450px;
		height: auto;
	}
}
</style>
