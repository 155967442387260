<template>
	<section>
		<div class="content-header">
			<TnHeading
				v-if="heading"
				v-editable-string="'content.heading'"
				class="content--heading margin-bottom-l"
				responsive
				size="l"
				type="h2"
			>
				{{ heading }}
			</TnHeading>
			<RichText
				v-if="text"
				:text="text"
				class="content--text"
				editable-html="content.text"
			/>
			<RichText
				v-if="smallText"
				:text="smallText"
				class="content--text__small"
				editable-html="content.smallText"
			/>

			<TnButton
				v-if="link && link.displayAsButton"
				:dark="dark"
				:data-comp-cat="bedriftCategory"
				:data-comp-lead="bedriftLead"
				:size="$store.getters.isMobile ? 's' : 'l'"
				class="margin-top-l"
				icon-right="cta-right"
				v-bind="link"
			>
				{{ link.text }}
			</TnButton>
			<LinkInline
				v-else-if="link && !link.displayAsButton"
				v-bind="link"
				:dark="dark"
				:data-comp-cat="bedriftCategory"
				:data-comp-lead="bedriftLead"
				:size="$store.getters.isMobile ? 's' : 'l'"
				chevron-right
				class="link margin-top-l"
				editable
			/>
		</div>
		<div v-if="showAddressSearch">
			<TComponent
				:component="addressSearch"
				:pagemeta="{}"
				style="text-align: left"
			/>
		</div>
	</section>
</template>

<script>
export default defineNuxtComponent({
	name: "Banner5050Content",

	props: {
		heading: {
			type: String,
			default: "",
		},
		text: {
			type: String,
			default: "",
		},
		smallText: {
			type: String,
			default: "",
		},
		link: {
			type: Object,
		},
		bedriftLead: {
			type: String,
		},
		bedriftCategory: {
			type: String,
		},
		dark: {
			type: Boolean,
		},
		addressSearch: {
			type: Object,
			default: () => ({}),
		},
		showAddressSearch: {
			type: Boolean,
			default: false,
		},
	},
});
</script>

<style lang="scss" scoped>
.content-header {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;

	h2 {
		margin: 0;
		display: inline-block;
		color: inherit;

		@media screen and (max-width: $size-screen-xs) {
			margin-bottom: $spacing-m;
		}
	}

	.content--text {
		@include font-text-l;
	}

	.richtext p:last-child {
		margin-bottom: 0;
	}

	.content--text {
		&__small {
			padding-top: $spacing-m;
			color: $color-neutrals-600-shade;

			@include font-text-xs;
		}
	}
}
</style>
